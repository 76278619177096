    body {
      font-family: Arial, Helvetica, sans-serif;
      background-color: black;
      color: white;
      text-align: center;
      margin: 0;
      padding: 0;
    }

    #banner_text {
      margin: 0;
      padding: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      color: black;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
    }

    #top_banner {
      margin-top: 0;
      background-color: #f19fe6;
    }

    #header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      border-bottom: 3px solid white;
      margin-bottom: 20px;

    }

    #brand {
      margin-left: 20px;
      margin-bottom: 0;
      margin-top: 0;
      color: white;
      font-size: 28px;
      font-weight: bold;

    }

    #header_links_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 20px;
    }

    .header_links a {
      margin-right: 20px;
      margin-bottom: 0;
      margin-top: 0;
      color: white;
      font-size: 15px;
      font-weight: 500;
      text-decoration: none;
      cursor: pointer;

    }

    #connect {
      color: greenyellow;
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
    }

    #body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;
      font-weight: bold;
    }

    .desc_box {
      width: 46%;
    }

    .desc_box h4 {
      font-size: 18px;
      font-weight: 400;
      font-style: italic;
    }

    #body_title {
      padding: 0;
      font-size: 50px;
      font-weight: bold;
      margin-bottom: 50px;
    }

    .faq_question {
      cursor: pointer;
      padding: 10px;
      background-color: transparent;
      border: 1px solid #ccc;
      border-bottom: none;
      padding: 0;
      padding-left: 30px;
      padding-right: 30px;
      margin-bottom: 0px;
      margin-right: 25%;
      margin-left: 25%;
      display: flex;
      justify-content: space-between;
      justify-items: center;
    }

    .question {
      font-size: 30px;
      font-weight: bolder;
      color: white;
      margin-bottom: 10px;
      text-align: left;
    }

    svg{
      margin-top: 3%;
    }

    .faq_answer_container {
      height: 0;
      overflow: hidden;
      transition: height 0.3s ease;
      border: 1px solid #ccc;
      border-top: none;
      border-top: none;
      padding: 0;
      padding-left: 50px;
      padding-right: 50px;
      margin-bottom: 10px;
      margin-right: 25%;
      margin-left: 25%;
      margin-top: 0;
      background-color: transparent;
    }

    .answer {
      font-size: 25px;
      font-weight: 400;
      color: white;
      text-align: left;
      margin-left: 50px;
      margin-right: 20px;

    }

    .faq_answer_container.expanded {
      padding: 10px;
    }

    #cta_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 50px;
      margin-top: 50px;
      margin-right: 30%;
      margin-left: 30%;
      border: 2px solid white;
      border-radius: 10px;
      height: 250px;

    }

    #cta_question {
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 5px;
      color: white;
    }

    #cta_advice {
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 20px;
      margin-top: 5px;
      color: rgba(211, 211, 211, 0.753);
    }

    #cta_button, #automate_button, #close_button {
      background-color: transparent;
      border: 2px solid #f19fe6;
      border-radius: 20px;
      color: white;
      padding: 10px;
      font-size: 20px;
      font-weight: 600;
      cursor: pointer;
      margin-bottom: 10px;
      margin-top: 10px;
      cursor: pointer;
    }

    #cta_button:hover,
    #automate_button:hover,
    #close_button:hover {
      background-color: #f19fe6;
      color: black;
      border: 2px solid black;
    }

    #automate_button, #close_button{
      width: 55%;
    }

    #close_button{
      color: black;
    }


    .footer {
      background-color: whitesmoke;
    }

    .footer-link-container {
      display: flex;
      justify-content: end;
      align-items: start;
      width: 100%;
      height: 260px;
      margin-top: 4%;
      margin-bottom: 0;
    }

    .uo-list {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .list-heading {
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      font-size: 23px;
      margin-bottom: 15px;
      color: #1d1d1f;
      text-align: left;
    }

    .uo-list li {
      margin-bottom: 10px;
      text-align: left;
    }

    .uo-list li a {
      text-decoration: none;
      color: #1d1d1f;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      font-size: 18px;
      font-weight: bold;
      text-decoration: underline;
    }

    hr {
      background-color: #1d1d1f21;
      height: 1px;
      border: none;
    }

    .policy-links {
      display: flex;
      justify-content: start;
      align-items: center;

    }

    .copyright {
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      font-size: 14px;
      color: #1d1d1f;
      font-weight: 500;
      height: 40px;
      margin-top: 0;
      margin-bottom: 0px;

    }

    .policy-links a {
      margin-right: 15px;
      text-decoration: underline;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      font-size: 13px;
      color: #1d1d1f;
      font-weight: 400;
    }
    
    .popup {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }

    .popup-content {
      background-color: white;
      padding: 30px;
      border-radius: 10px;
      text-align: center;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
      max-width: 500px;
      width: 100%;
    }

    .popup-content h2 {
      margin-bottom: 15px;
      color: #333;
    }

    .popup-content p {
      margin-bottom: 20px;
      color: #666;
    }

    @media screen and (max-width: 768px) {

    #banner_text {
        font-size: 14px;
      }
      #brand{
        font-size: 20px;
      }
      
      .header_links a{
        display: none;
      }

     #connect{
      display: block;
       font-size: 15px;
     }
     
     #body_title{
        font-size: 30px;
     }
      
     .desc_box {
        width: 65%;
     }

     .desc_box h4 {
        font-size: 16px;
     }

     .faq_question {
        padding-left: 20px;
        padding-right: 20px;
        margin-right: 10%;
        margin-left: 10%;
      }

      .faq_answer_container {
        padding-left: 25px;
        padding-right: 25px;
        margin-right: 10%;
        margin-left: 10%;
      }

      #cta_container {
        margin-right: 10%;
        margin-left: 10%;

      }
      #cta_advice {
        font-size: 16px;
      }

      .footer{
        padding-left: 5%;
        padding-right: 5%;
      }

      .footer-link-container {
        flex-direction: column;
        height: auto;
      }

      .footer-desc{
        flex-basis: 10%;
      }

      .copyright {
        margin-top: 20px;
      }
    } 


    @media screen and (max-width: 425px) {

      #banner_text {
        font-size: 12px;
      }

      #header {
        padding: 5px;

      }

      #brand {
        font-size: 18px;
      }

      .header_links a {
        display: none;
      }

      #connect {
        display: block;
        font-size: 13px;
      }

      #body_title {
        font-size: 25px;
        margin-top: 40px;
      }

      .desc_box {
        width: 75%;
      }

      .desc_box h4 {
        font-size: 14px;
        margin-top: 0px;
      }

      .faq_question {
        padding-left: 20px;
        padding-right: 20px;
        margin-right: 7%;
        margin-left: 7%;
      }

      .question {
        font-size: 20px;
      }

      svg {
        margin-top: 5%;
      }

      .faq_answer_container {
        padding-left: 25px;
        padding-right: 25px;margin-right: 7%;
          margin-left: 7%;
      }

      .answer{
        font-size: 100%;
      }

      #cta_container {
        margin-right: 7%;
        margin-left: 7%;
        padding: 15px;
        height: 150px;

      }
      #cta_question {
        font-size: 19px;
      }
      #cta_advice {
        font-size: 13px;
      }

      .footer {
        padding-left: 3%;
        padding-right: 6%;
      }

      .uo-list li a {
        font-size: 15px;
      }

      .footer-desc {
        flex-basis: 15%;
      }

     
    }